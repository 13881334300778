// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/navbar/navbar';  // Ensure Navbar.js exists
import Dashboard from './components/Dashboard/Dashboard';  // Ensure Dashboard.js exists
import Login from './components/Login/Login';  // Ensure Login.js exists

const App = () => {
  return (
    <Router>
      <Navbar />  {/* Navbar will show on every page */}
      <Routes>
        <Route path="/" element={<Login />} />  {/* Homepage/Login page */}
        <Route path="/analytics" element={<Dashboard />} />  {/* Dashboard route */}
      </Routes>
    </Router>
  );
};

export default App;
