// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';  // Import both Line and Bar chart types
import './Dashboard.css';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,  // Add BarElement for bar charts
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register the necessary chart components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,  // Register BarElement
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [chartData, setChartData] = useState({
    labels: [],  // Empty array for labels initially
    datasets: []  // Empty array for datasets initially
  });

  const fetchInstagramData = async () => {
    // Simulate API data fetch for chart
    const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];  // Labels for days
    const impressionsData = [1500, 2000, 1200, 3000, 4050];  // Line chart data
    const interactionsData = [20, 30, 48, 10, 19];  // Bar chart data

  

    setChartData({
      labels: labels,
      datasets: [
        {
          type: 'line',  // Line chart for 'Impressions'
          label: 'Impressions',
          data: impressionsData,
          borderColor: 'rgba(75,192,192,1)',
          backgroundColor: 'rgba(75,192,192,0.2)',
          tension: 0.4,  // Smooth line
          fill: false,
          yAxisID: 'y',  // Assign this to y-axis 'y'
        },
        {
          type: 'bar',  // Bar chart for 'New followers'
          label: 'New Followers',
          data: interactionsData,
          backgroundColor: 'rgba(54, 162, 235, 0.6)',  // Blue bars
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          yAxisID: 'y1',  // Assign this to second y-axis 'y1'
        },
      ],
    });
  };

  useEffect(() => {
    fetchInstagramData();  // Fetch data on component mount
  }, []);

  return (
    <div className="dashboard">
      <h2>Your weekly Analytics</h2>
      <div className="chart-container">
        {chartData.labels.length > 0 && (
          <Bar  // Use Bar for mixed (stacked) charts
            data={chartData}
            options={{
              responsive: true,  // Ensures the chart is responsive
              maintainAspectRatio: false,  // Allows the chart to resize based on the container
              scales: {
                y: {
                  beginAtZero: true,
                  stacked: true,  // Enable stacking
                  position: 'left',  // Left y-axis for line chart
                },
                y1: {
                  beginAtZero: true,
                  stacked: true,  // Enable stacking for bars
                  position: 'right',  // Right y-axis for bar chart
                  grid: {
                    drawOnChartArea: false,  // Only show grid lines for one axis
                  },
                },
              },
            }}
          />
        )}
      </div>
      <div>
        <h2>Your funnel stats</h2>
      </div>
    </div>
  );
};

export default Dashboard;
