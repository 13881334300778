// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";  // Zorg ervoor dat Firestore is geïmporteerd

const firebaseConfig = {
    apiKey: "AIzaSyA-bKUH9sVSHVmZ4EkhHnEXbxjRBr-UBzE",
    authDomain: "sosigrow-db.firebaseapp.com",
    databaseURL: "https://sosigrow-db-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "sosigrow-db",
    storageBucket: "sosigrow-db.appspot.com",
    messagingSenderId: "344106994992",
    appId: "1:344106994992:web:b1636cae024b33f99f0cf5",
    measurementId: "G-KKWR41ZBYR"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Exporteer auth en db (Firestore)
export const auth = getAuth(app);
export const db = getFirestore(app);  // Voeg deze regel toe om Firestore te exporteren
