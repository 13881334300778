// src/components/Login.js
import React, { useState } from 'react';
import { auth, db } from './firebaseConfig';  // Importeer Firestore en Auth
import { FacebookAuthProvider, signInWithPopup } from 'firebase/auth';  // Gebruik Facebook-authenticatie
import { useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';  // Voeg Firestore-methoden toe
import './Login.css';

const Login = () => {
  const [error, setError] = useState('');
  const navigate = useNavigate();  // Gebruik useNavigate voor navigatie

  const handleFacebookLogin = async () => {
    const provider = new FacebookAuthProvider();  // FacebookAuthProvider instance
    provider.addScope('pages_show_list');  // Voeg de juiste permissies toe om pagina's te lezen
    provider.addScope('pages_read_engagement');  // Voeg scope toe om de engagement te lezen

    try {
      const result = await signInWithPopup(auth, provider);  // Gebruik signInWithPopup voor Facebook-login
      const user = result.user;  // Firebase gebruiker
      const credential = FacebookAuthProvider.credentialFromResult(result);
      const accessToken = credential.accessToken;  // Toegangstoken van Facebook

      // Sla gebruikersgegevens op in Firestore
      await setDoc(doc(db, 'users', user.uid), {
        name: user.displayName,  // Naam van de gebruiker
        email: user.email,  // E-mail van de gebruiker
        facebookId: user.providerData[0].uid,  // Facebook-gebruikers-ID
        accessToken: accessToken,  // Facebook toegangstoken
        createdAt: new Date(),  // Datum van aanmaak
        subscribed: false  // Voeg extra velden toe, zoals abonnement
      });

      // Navigeer de gebruiker naar het dashboard
      navigate('/dashboard');

    } catch (err) {
      setError('Failed to log in with Facebook. Please try again.');
      console.error(err);
    }
  };

  return (
    <div className="login-container">
      <h2>Login with Facebook</h2>
      {error && <p className="error-message">{error}</p>}
      <button onClick={handleFacebookLogin} className="facebook-login-button">
        Log in with Facebook
      </button>
    </div>
  );
};

export default Login;
