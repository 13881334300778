// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'

const Navbar = () => {
  return (
    <div className="navbar">
      <Link className="navbarItem" to="/">Home</Link>
      <Link className="navbarItem" to="/analytics">Analytics</Link>
      <Link className="navbarItem" to="/contact">Contact</Link>
    </div>
  );
};

export default Navbar;
